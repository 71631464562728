// This hooks ups client-side app analytics
// it's based on how the google analytics plugin works for gatsby
// https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-analytics/src/gatsby-browser.js

exports.onRouteUpdate = ({ location, prevLocation }) => {
    // Run both clear and app insights for a bit, then drop app insights
  
    // prettier-ignore
    // ;(function(c,l,a,r,i,t,y){
    //       c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    //       t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    //       y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    //   })(window, document, "clarity", "script", "3w5kyel345");
  
    var sdkInstance = "appInsightsSDK"
    window[sdkInstance] = "appInsights"
    const config = {
      instrumentationKey: "78a8fb52-a225-4c66-ac08-92fad1c1ade1",
      // loggingLevelConsole: 1
    }
  
    let hasLocalStorage = false
    try {
      hasLocalStorage = typeof localStorage !== `undefined`
    } catch (error) {}
  
    try {
      // prettier-ignore
      // @ts-ignore
      
  
      const locationWithoutPlaygroundCode = location.pathname
        .split("#code")[0]
        .split("#src")[0]
  
      const prevHref = (prevLocation && prevLocation.pathname) || ""
      const previousLocationWithoutPlaygroundCode = prevHref
        .split("#code")[0]
        .split("#src")[0]
  
      const referrerWithoutPlaygroundCode =
        document.referrer && document.referrer.split("#code")[0].split("#src")[0]
  
      // @ts-ignore
      
    } catch (error) {
      console.error("Error with Application Insights")
      console.error(error)
    }
  }